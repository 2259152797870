
/* eslint-disable */

import { ref, reactive, onMounted, provide } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import { useRouter } from "vue-router";
import CfGridList from "@/components/grid/Main.vue";
// import CfTabsBar from "@/components/tabs-bar/Main.vue";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import CfToolBar from "@/components/tool-bar/Main.vue";
export default defineComponent({
  components: {
    // CfTabsBar,
    CfToolBar,
    CfTabs,
    CfSearchBar,
    CfGridList,
  },
  setup() {
    const router = useRouter();
    return {
      GridList: [
        {
          id: 1,
          name: "Project name",
          date: "Jan 1, 2021",
          model: "Model name",
          owner: "me",
          href: "/album/image",
          group: "hidden",
        },
        {
          id: 2,
          name: "Project name",
          date: "Jan 1, 2021",
          model: "Model name",
          owner: "me",
          href: "/album/image",
          group: "",
        },
        {
          id: 3,
          name: "Project name",
          date: "Jan 1, 2021",
          model: "Model name",
          owner: "baba",
          href: "/album/image",
          group: "hidden",
        },
        {
          id: 4,
          name: "Project name",
          date: "Jan 1, 2021",
          model: "Model name",
          owner: "me",
          href: "/album/image",
          group: "hidden",
        },
        {
          id: 5,
          name: "Project name",
          date: "Jan 1, 2021",
          model: "Model name",
          owner: "me",
          href: "/album/image",
          group: "",
        },
        {
          id: 6,
          name: "Project name",
          date: "Jan 1, 2021",
          model: "Model name",
          owner: "me",
          href: "/album/image",
          group: "hidden",
        },
      ],
      tabs: [
        { name: "PROJECT", href: "/project", current: false },
        { name: "ALBUM", href: "/album", current: false },
        { name: "MODEL", href: "/model", current: true },
      ],
      toolbar: [
        {
          tooltipMsg: "Sharing group",
          icon: "icon-user",
        },
        {
          tooltipMsg: "Delete",
          icon: "icon-delete",
        },
        {
          tooltipMsg: "Rename",
          icon: "icon-edit",
        },
      ],
    };
  },
  methods: {},
});
